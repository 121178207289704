import React, { useEffect, useState } from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import * as styles from './styles.module.scss'

interface BreadcrumbComponentProps {
  replacedLabel?: string
}

const BreadcrumbComponent: React.FC<BreadcrumbComponentProps> = ({
  replacedLabel,
}) => {
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([])

  function removeHyphens(input: string): string {
    const parts = input.split('-')
    const capitalizedParts = parts.map(
      (part) => part.charAt(0).toUpperCase() + part.slice(1)
    )
    const result = capitalizedParts.join(' ')
    return result
  }

  useEffect(() => {
    const currentUrl = window.location.pathname
    const urlParts = currentUrl.split('/').filter((part) => part !== '')
    setBreadcrumbs(urlParts)
  }, [])

  return (
    <nav aria-label="breadcrumb" className={styles.breadcrumbNav}>
      <ol className={`breadcrumb`}>
        <li className={`breadcrumb-item`}>
          <AnchorLink to="/">Home</AnchorLink>
        </li>
        {breadcrumbs.map((part: string, index: number) => (
          <li
            key={index}
            className={`breadcrumb-item ${
              index === breadcrumbs.length ? `active` : ``
            }`}
            aria-current="page"
          >
            {index < breadcrumbs.length - 1 ? (
              <AnchorLink to={`/${breadcrumbs.slice(0, index + 1).join('/')}`}>
                {part}
              </AnchorLink>
            ) : (
              <span>{replacedLabel ? replacedLabel : removeHyphens(part)}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default BreadcrumbComponent
