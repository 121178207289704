import React from 'react'
import clsx from 'clsx'
import { PageProps } from 'gatsby'
import { Layout } from '../../../components/layout'
import { SEO } from '../../../components/seo'
import { Menu } from '../../../components/menu'

import * as styles from '../../../styles/pages/solutions.module.scss'
import mainHeader from '../../../images/solutions/controladoria/preco-de-vendas.jpeg'

import { Contact } from '../../../components/contact/contact'
import { Footer } from '../../../components/footer'
import { FaqSection } from '../../../components/faq'
import { MethodologySection } from '../../../components/methodology'
import { SliderCustomers } from '../../../components/sliderCustomers/sliderCustomers'
import { StatisticsSection } from '../../../components/statistics'
import { controladoriaList } from '../../../utils/customersList'
import BreadcrumbComponent from '../../../components/breadcrumb'

const faqItems = [
  {
    question: 'Quais são os modelos de precificação de produtos e serviços?',
    answer: `Existem vários modelos de precificação, os quais incluem a precificação baseada em custos (que destacamos acima); precificação baseada na concorrência, que considera os preços praticados pelos concorrentes; e precificação baseada na demanda, ajustando os preços de acordo com a disposição do mercado em pagar. A escolha do melhor modelo é definida junto com a consultoria, a depender da natureza do negócio e dos objetivos estratégicos.`,
  },
  {
    question:
      'Quais os principais desafios na precificação de produtos e serviços?',
    answer:
      'Os desafios na precificação de produtos e serviços incluem equilibrar competitividade com rentabilidade, compreender a percepção do valor pelo cliente, adaptar-se a flutuações do mercado e calcular custos de forma precisa. Lidar com a concorrência agressiva e o valor percebido pelo cliente, também são obstáculos. Para superar estes desafios e ainda preservar a saúde financeira da empresa, a consultoria pode te ajudar.',
  },
  {
    question:
      'Como garantir a confiabilidade das informações gerenciais de apuração de custos e respectiva formação de preços de venda?',
    answer:
      'Garantir a confiabilidade das informações gerenciais de apuração de custos requer rigor nos processos contábeis, auditorias internas e uso de sistemas integrados (ERPs). A padronização de métodos de alocação de custos e conciliação entre fontes são essenciais. Investir na capacitação da equipe, implementar controles internos e adotar tecnologias de automação (RPA), contribuem para a precisão dos dados.',
  },
  {
    question:
      'Como entender se é o momento de contratar uma consultoria para precificação de produtos e serviços?',
    answer:
      'O processo de precificação impacta decisões estratégicas, como a viabilidade de expansão, influencia a margem de lucro desejada e molda a percepção de valor do cliente. Ajustes na precificação também orientam estratégias promocionais, gestão de estoque e investimentos em inovação. Se a sua empresa se encontra diante destes desafios, a consultoria pode te ajudar.',
  },
]

const methodologyItems = [
  {
    count: 1,
    title: 'Apontamento de custos',
    description: (
      <>
        Um bom processo de apuração de custos é fundamental para a precificação
        de produtos e serviços. Este processo se inicia garantindo a
        confiabilidade dos dados: apontamentos de produção, estoque, ociosidade,
        ineficiência, perdas, devoluções). Sem os apontamentos, não há custos e
        nem precificação.
      </>
    ),
  },
  {
    count: 2,
    title: 'Apuração de custos',
    description: (
      <>
        Após garantidos os apontamentos de custos, é necessário realizar a
        alocação ou apuração dos mesmos, classificando-os em custos diretos ou
        indiretos e ainda em fixos ou variáveis. É preciso se atentar
        principalmente aos custos indiretos e aos custos fixos.
      </>
    ),
  },
  {
    count: 3,
    title: 'Critério de rateio',
    description: (
      <>
        O rateio de custos é um método para separar custos de acordo com os
        elementos que participam dele. Geralmente o rateio envolve processos de
        produção, movimentação, vendas e marketing, dependendo da atividade da
        empresa. Alguns métodos de rateio são: por absorção, headcount,
        atividade e faturamento.
      </>
    ),
  },
  {
    count: 4,
    title: 'Formação do preço de venda',
    description: (
      <>
        Após estruturação de um processo de custeio adequado, é possível tomar
        decisões para a formação do preço de venda de produtos e serviços,
        levando em consideração, além dos custos, estudos de concorrência e
        demanda, definindo estratégias para ganhar competitividade diante do
        mercado.
      </>
    ),
  },
]

const featuresItems = [
  {
    title: `Precificação baseada em custos`,
    description: (
      <>
        Trabalhamos com o markup, que é um método de precificação com base no
        custo. O markup é um índice multiplicador aplicado sobre o custo de um
        produto/serviço para a formação do preço de venda. Este índice contempla
        os impostos, taxas, despesas e o percentual de lucro desejado.
      </>
    ),
  },
  {
    title: `Regime de tributação`,
    description: (
      <>
        É imprescindível considerar as taxas e impostos no processo de
        precificação. Devem ser considerados os principais impostos que incidem
        sobre as receitas de vendas de produtos e serviços, como ICMS, PIS,
        COFINS e IPI, cada um com suas respectivas alíquotas , de acordo com a
        localização e o regime de tributação.
      </>
    ),
  },
  {
    title: `Margem de contribuição`,
    description: (
      <>
        A margem de contribuição representa o quanto o lucro da venda de cada
        produto/serviço contribuirá para a empresa cobrir todos os seus custos e
        despesas fixas e ainda gerar lucro. Se a margem de contribuição não é
        conhecida, a empresa pode estar vendendo bastante e ainda assim estar no
        prejuízo.
      </>
    ),
  },
  {
    title: `Tabelas de comissões`,
    description: (
      <>
        É importante levar em consideração as comissões sobre vendas no momento
        da precificação. Para criar um bom modelo de comissionamento é preciso
        definir metas, taxas de comissões e datas de pagamento. O
        comissionamento é estratégico para a precificação.
      </>
    ),
  },
]

const ControladoriaPrecosPage: React.FC<PageProps> = ({ location }) => {
  const { pathname } = location
  return (
    <Layout location={location}>
      <SEO title="High Jump - Formaçāo de Preços" />
      <div className={styles.solutions}>
        <Menu mainFolder={pathname} />
        <main>
          <section className={clsx(styles.intro, styles.singleTitle)}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={clsx(styles.mainTitle)}>
              <h1>Formação do preço de venda como estratégia competitiva</h1>
              <p>
                Tenha um processo de formação de preços assertivo, maximize o
                lucro, cubra custos e ganhe produtividade
              </p>
            </div>
          </section>
          <div className={styles.container}>
            <BreadcrumbComponent replacedLabel="Formaçāo de Preços" />
            <div className={styles.intention}>
              <div className={styles.title}>
                <h1>Nosso Propósito</h1>
                <span></span>
                <p>
                  Aplicar boas práticas de precificação de produtos e serviços
                  para alavancar vendas e a lucratividade
                </p>
              </div>
              <div className={styles.mainFeatures}>
                <div className={styles.listItems}>
                  {featuresItems.map((item, key) => (
                    <div className={styles.item} key={key}>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <MethodologySection
              items={methodologyItems}
              descriptionSection="Conheça os 04 principais passos de nossa metodologia para estruturação do processo de formação do preço de venda"
            />
          </div>

          <div className={styles.cases}>
            <div className={styles.title}>
              <h1>Quem conhece, confia!</h1>
            </div>
            <SliderCustomers customers={controladoriaList} />
          </div>

          <div className={styles.statistics}>
            <StatisticsSection />
          </div>

          <div className={styles.container}>
            <div className={styles.features}>
              <div className={styles.title}>
                <h1>Diferenciais</h1>
              </div>
              <div className={styles.items}>
                <div className={styles.div1}>
                  <h2>Equipe multidisciplinar</h2>
                  <p>
                    Especialistas por área, trazendo as melhores práticas de
                    mercado
                  </p>
                </div>
                <div className={styles.div2}>
                  <h2>Boas práticas FIPECAFI</h2>
                  <p>Aplicação de boas práticas contábeis e fiscais</p>
                </div>
                <div className={styles.div3}>
                  <h2>Confiança no resultado</h2>
                  <p>Almejando o retorno sobre o investimento</p>
                </div>
                <div className={styles.div4}>
                  <h2>Modalidade híbrida</h2>
                  <p>
                    Execução presencial e/ou remota em comum acordo com o
                    cliente
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Contact locationPath={pathname} />
          <FaqSection items={faqItems} />
          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default ControladoriaPrecosPage
